<template>
    <ion-page class="page home">
        <ion-header :translucent="true" v-if="user">
            <ion-toolbar color="primary">
                <ion-title class="ion-text-center">{{ user.username }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div class="content">
                <div v-if="snapshot" class="preview">
                    <ion-img :src="snapshot.webPath" />
                </div>
                <div class="ocr">
                    <div class="progress" v-if="ocr.running">
                        <progress :value="ocr.progress" max="1" style="width: 100%;"></progress>
                        <!-- {{ ocr.message }}... -->
                    </div>
                    <div v-if="ocr.result !== null">
                        <input class="result" type="text" v-model="ocr.result">
                    </div>
                </div>
                <!-- Invalid Player -->
                <ion-card v-if="validation" style="margin: 0;">
                    <ion-card-header :color="(validation.is_ok) ? 'success' : 'danger'">
                        <ion-card-title class="ion-text-center">
                            <span v-if="validation.is_ok">
                                <ion-icon :icon="icons.checkmarkOutline" style="position: relative; top: 2px;"></ion-icon> ACCES PERMIS
                            </span>
                            <span v-if="!validation.is_ok">
                                <ion-icon :icon="icons.banOutline" style="position: relative; top: 2px;"></ion-icon> ACCES INTERZIS
                            </span>
                        </ion-card-title>
                    </ion-card-header>
                    <ion-card-content class="ion-text-center"><br>
                        <div class="player">
                            <b>
                                <span v-if="validation.player_status == 'PLAYER_FOUND'">
                                    <ion-icon :icon="icons.personOutline" style="position: relative; top: 2px;"></ion-icon> {{ validation.first_name }} {{ validation.last_name }}
                                </span>
                                <span v-else>
                                    <ion-icon :icon="icons.personOutline" style="position: relative; top: 2px;"></ion-icon> JUCATOR NEIDENTIFICAT
                                </span>
                            </b>
                        </div>
                        <div class="status" v-if="validation.sv_name">
                            Status: {{ validation.sv_name }}
                        </div>
                        <div class="status" v-if="validation.start_date">
                            De la: {{ moment(validation.start_date, 'DD/MM/YYYY') }}
                        </div>
                        <div class="status" v-if="validation.end_date">
                            Pana la: {{ moment(validation.end_date, 'DD/MM/YYYY') }}
                        </div>
                        <div class="details" v-if="validation.details">
                            Detalii: {{ validation.details }}
                        </div>
                    </ion-card-content>
                </ion-card>
                <div class="navigation" v-if="!ocr.running">
                    <ion-button v-if="ocr.result === null" color="primary" @click="capture" expand="block">SCANEAZĂ DOCUMENT</ion-button>
                    <ion-button v-if="ocr.result !== null && !validation" color="success" @click="checkPlayer" expand="block">CONFIRMĂ CNP</ion-button>
                    <ion-button v-if="ocr.result !== null" @click="reset" fill="clear" color="medium" expand="block">ÎNAPOI</ion-button>
                    <ion-button v-if="ocr.result === null" @click="logout" fill="clear" color="medium" expand="block">SCHIMBĂ LOCAȚIA</ion-button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { defineComponent } from 'vue';
import Tesseract from 'tesseract.js';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg, IonButton, IonIcon } from '@ionic/vue';
import { IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonCard } from '@ionic/vue';
import { toastController } from '@ionic/vue';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/userStore.js';
import axios from 'axios';
import moment from 'moment';
import { banOutline, checkmarkOutline, personOutline } from 'ionicons/icons';
export default defineComponent({
    name: "HomePage",
    data() {
        return {
            snapshot: null,
            ocr: {
                running: false,
                result: null,
                progress: 0,
                message: '',
            },
            validation: null,
            user: null,
            icons: {
                banOutline: banOutline,
                checkmarkOutline: checkmarkOutline,
                personOutline: personOutline,
            }
        }
    },
    components: {
        'ion-content': IonContent,
        'ion-title': IonTitle,
        'ion-toolbar': IonToolbar,
        'ion-header': IonHeader,
        'ion-img': IonImg,
        'ion-button': IonButton,
        'ion-page': IonPage,
        'ion-icon': IonIcon,
        'ion-card': IonCard,
        'ion-card-content': IonCardContent,
        'ion-card-header': IonCardHeader,
        'ion-card-subtitle': IonCardSubtitle,
        'ion-card-title': IonCardTitle,
    },
    computed: {
        ...mapStores(useUserStore),
    },
    async mounted() {

    },
    async ionViewWillEnter() {
        await this.getUser();
    },
    methods: {
        moment(timestamp, format) {
            return moment(timestamp).format(format);
        },
        async checkPlayer() {
            try {

                if (this.ocr.result.length < 13) {
                    let toast = await toastController.create({
                        message: 'CNP-ul are minim 13 caractere',
                        duration: 1500,
                        color: 'danger',
                        position: 'bottom',
                    });
                    toast.present();

                    return;
                }

                let postData = new FormData();
                postData.append('client_id', this.user.business_id);
                postData.append('cnp', this.ocr.result);

                let response = await axios.post(this.userStore.login.cloud_url + '/api/1.0/unigens/mobile_check_player_access.php', postData);
                if (response.data.status == 'OK') {
                    this.validation = response.data.result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getUser() {
            try {
                let postData = new FormData();
                postData.append('user_id', this.userStore.login.user_id);
                let response = await axios.post(this.userStore.login.cloud_url + '/api/1.0/unigens/mobile_crc_login.php', postData);
                if (response.data.result && response.data.result.is_ok == true) {
                    this.user = response.data.result;
                } else {
                    let toast = await toastController.create({
                        message: 'Utilizator inactiv!',
                        duration: 1500,
                        color: 'danger',
                        position: 'bottom',
                    });
                    toast.present();
                    await this.logout();
                }
            } catch (error) {
                console.log(error);
                await this.logout();
            }
        },
        async logout() {
            await this.userStore.logout();
            this.$router.push('login');
        },
        async reset() {
            this.snapshot = null;
            this.validation = null;
            this.ocr = {
                running: false,
                result: null,
                progress: 0,
                message: '',
            }
        },
        async capture() {
            try {
                this.snapshot = null;
                let photo = await Camera.getPhoto({
                    resultType: CameraResultType.Uri,
                    source: CameraSource.Camera,
                    quality: 100,
                });
                this.snapshot = photo;
                this.runOCR();
            } catch (error) {
                console.log(error);
            }
        },
        async parseDocument(text) {
            let lines = await text.split("\n");
            let idCode = lines[lines.length - 2];
            let nameLines = lines[lines.length - 3];

            if (nameLines.substring(0, 2) == "ID") {
                return this.parseID(idCode);
            } else if (nameLines.substring(0, 2) == "PE") {
                return this.parsePE(idCode);
            } else {
                // unkown document type, still try parse id
                return this.parseID(idCode);
            }
        },
        async parseID(idCode) {
            idCode = idCode.split("<")[1];
            let gender = (idCode.substring(11, 12) == "M") ? 1 : 2;
            let dob = idCode.substring(4, 10);
            let end = idCode.slice(-7).substring(0, 6);
            let cnp = gender + dob + end;
            return cnp;
        },
        async parsePE(idCode) {
            idCode = idCode.split("<")[0];
            let gender = (idCode.substring(20, 21) == "M") ? 1 : 2;
            let dob = idCode.substring(13, 19);
            let end = idCode.slice(-6);
            let cnp = gender + dob + end;

            console.log(idCode.substring(20, 22));

            return cnp;
        },
        async runOCR() {

            this.ocr.result = null;
            this.ocr.running = true;
            this.ocr.progress = 0;
            this.ocr.message = '';

            await Tesseract.recognize(
                this.snapshot.webPath,
                'ron', {
                    logger: m => {
                        this.ocr.progress = m.progress;
                        this.ocr.message = m.status;
                    }
                }
            ).then(async ({ data: { text } }) => {
                try {
                    this.ocr.result = await this.parseDocument(text);
                    this.ocr.running = false;
                } catch (error) {
                    console.log(error);
                    this.ocr.result = '';
                    this.ocr.running = false;
                }
            }).catch(error => {
                console.log(error);
            })

        },
    }
})
</script>