<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>
<script>
import { defineComponent } from 'vue';
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { useRouter } from 'vue-router'; 
export default defineComponent({
  name: 'App',
  components: { 
    IonApp,
    IonRouterOutlet,
  },
  methods: {
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  mounted() {
    console.log('Initializing HomePage');

  }
});
</script>
