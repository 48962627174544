import { defineStore } from 'pinia'
export const useUserStore = defineStore("user", {
    state: () => {
        return {
            login: (localStorage.getItem('login')) ? JSON.parse(localStorage.getItem('login')) : null,
        }
    },
    actions: {
        setLogin(login) {
            this.login = login;
            localStorage.setItem('login', JSON.stringify(login));
        },
        logout() {
            this.login = null;
            localStorage.removeItem('login'); 
        }
    },
});