<template>
    <ion-page class="page login">
        <ion-header :translucent="true">
            <ion-toolbar color="primary">
                <ion-title class="ion-text-center">LOGIN</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div class="content">
                <div class="stream">
                    <p class="ion-text-center">Scaneaza codul QR pentru<br> a intra in aplicatie</p><br>
                    <qrcode-stream v-if="showCamera" @decode="onDecode">
                    </qrcode-stream>
                </div>
                <div class="navigation">
                    <ion-button @click="exit" fill="clear" color="medium" expand="block">ÎNCHIDE APLICAȚIA</ion-button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { defineComponent } from 'vue';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg, IonButton } from '@ionic/vue';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { App } from '@capacitor/app';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import Tesseract from 'tesseract.js';
import { QrcodeStream } from 'vue3-qrcode-reader';
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/userStore.js';
import barcodeScanner  from '@awesome-cordova-plugins/barcode-scanner';



export default defineComponent({
    name: "LoginPage",
    data() {
        return {
            loading: true,
            showCamera: false,
        }
    },
    components: {
        'ion-content': IonContent,
        'ion-title': IonTitle,
        'ion-toolbar': IonToolbar,
        'ion-header': IonHeader,
        'ion-button': IonButton,
        'ion-page': IonPage,
        'qrcode-stream': QrcodeStream,
    },
    computed: {
        ...mapStores(useUserStore),
    },
    async ionViewWillEnter() {
        this.showCamera = false;
    },
    async ionViewDidEnter() {
        this.showCamera = true;

        console.log('asda', barcodeScanner);

    },
    created() {
        Camera.requestPermissions();
    },
    methods: {
        exit() {
            App.exitApp();
        },
        async onDecode(value) {
            try {
                let json = JSON.parse(value.split("").reverse().join(""));
                await this.login(json);
            } catch (error) {
                console.log(error);
            }
        },
        async login(data) {
            console.log('login');
            this.userStore.setLogin(data);
            this.$router.push('/home');
        },
    }
})
</script>